import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: '#f5f4f1'
}));
export const Content = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
}));

export const Title = styled('div')(({ theme }) => ({
    fontSize: '1.5rem',
}));

export const Button = styled('button')(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    color: 'black',
    borderRadius: '20px',
    border: 'none',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
}));


export const Top = styled('div')(({ theme }) => ({
    fontSize: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '1rem',
}));

export const Box = styled('div')(({ theme }) => ({
    backgroundColor: '#fff',
    borderRadius: '1.5rem',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
}));

export const Header = styled('div')(({ theme }) => ({
    fontSize: '1.2rem',
    fontWeight: 'bold',
    paddingBottom: '10px',
}));

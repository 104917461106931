import React, { useState }                  from 'react'
import './App.scss'
import FacebookLogin                        from 'react-facebook-login'
import i18n                                 from './translation'
import MainRouter                           from './router'
import { I18nextProvider }                  from 'react-i18next'
import { BrowserRouter }                    from 'react-router-dom'
import AuthProvider                         from './hooks/AuthProvider'
import { GoogleOAuthProvider }              from '@react-oauth/google'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ThemeProvider }                    from 'styled-components'

function App() {
    const lightTheme = {
        colors: {
            // Define your color palette here
        },
        fonts: {
            // Define your fonts here
        },
        spacing: {
            // Define your spacing here
        },
        direction: 'ltr', // Define the text direction here (ltr or rtl)
    }

    const [ theme, setTheme ] = useState( lightTheme )
    const queryClient = new QueryClient()

    React.useEffect( () => {
        // Initialize Facebook SDK here from above
    }, [] )

    const responseFacebook = ( response: any ) => {
        console.log( response )
    }

    return (
        <ThemeProvider theme={ theme }>
            <QueryClientProvider client={ queryClient }>
                <I18nextProvider i18n={ i18n }>
                    <BrowserRouter>
                        <AuthProvider>
                            <GoogleOAuthProvider clientId="317022010811-ju6gcnn7vss1bd4n9g15hs3kc0pithm7.apps.googleusercontent.com">
                                <MainRouter />
                            </GoogleOAuthProvider>
                        </AuthProvider>
                    </BrowserRouter>
                    {/*<div>*/ }
                    {/*    <FacebookLogin*/ }
                    {/*        appId="317788801352094"*/ }
                    {/*        autoLoad={ true }*/ }
                    {/*        fields="name,email,picture"*/ }
                    {/*        callback={ responseFacebook }*/ }
                    {/*        scope="pages_manage_engagement,pages_manage_metadata,pages_manage_posts,pages_read_engagement,pages_read_user_engagement,pages_show_list,publish_video"*/ }
                    {/*    />*/ }
                    {/*</div>*/ }
                </I18nextProvider>
            </QueryClientProvider>
        </ThemeProvider>
    )
}


export default App

import React    from 'react'
import Loadable from '@loadable/component'

const Container = Loadable( () => import('../../common/Container') )
const TermsOfUse = () => {
    return (
        <Container>
            <div style={ { alignContent: 'center', width: '75%', margin: '0 11rem' } }>
                <h3>Terms of Use</h3>
                <p>Welcome to Kliper! These Terms of Use govern your access and use of our website and services. By accessing or using our platform, you agree to be bound by these
                    Terms and our <a href="/privacy-policy">Privacy Policy</a>.</p>

                <section>
                    <strong>1. Services</strong>
                    <p>Kliper provides marketing and advertising services that allow you to analyze campaign performance, schedule posts and ads on social networks, and receive
                        insights to improve your marketing strategies. Our services are provided solely for lawful purposes.</p>
                </section>

                <section>
                    <strong>2. User Accounts</strong>
                    <p>You may be required to create an account to access certain features of our platform. You are responsible for maintaining the confidentiality of your account
                        credentials and for all activities that occur under your account.</p>
                </section>

                <section>
                    <strong>3. User Content</strong>
                    <p>You retain ownership of any content, data, or information that you submit or upload to our platform ("User Content"). However, by providing User Content, you
                        grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, and display the User Content solely for the purpose of providing our
                        services to you.</p>
                </section>

                <section>
                    <strong>4. Prohibited Conduct</strong>
                    <p>You agree not to use our services for any unlawful or prohibited purposes, including but not limited to:</p>
                    <ul>
                        <li>Violating any applicable laws or regulations</li>
                        <li>Infringing upon the intellectual property rights of others</li>
                        <li>Uploading or distributing malicious code or viruses</li>
                        <li>Engaging in any activity that could disrupt or interfere with our services</li>
                    </ul>
                </section>

                <section>
                    <strong>5. Termination</strong>
                    <p>We reserve the right to terminate or suspend your access to our services at any time, with or without cause. Upon termination, you may request the deletion
                        of
                        your data in accordance with our <a href="/privacy-policy">Privacy Policy</a>.</p>
                </section>

                <section>
                    <strong>6. Intellectual Property</strong>
                    <p>All intellectual property rights in our platform, services, and content are owned by Kliper or its licensors. You may not use our intellectual property
                        without
                        our express written consent.</p>
                </section>

                <section>
                    <strong>7. Disclaimer of Warranties</strong>
                    <p>Our services are provided "as is" and "as available" without warranties of any kind, express or implied. We do not guarantee the accuracy, reliability, or
                        availability of our services.</p>
                </section>

                <section>
                    <strong>8. Limitation of Liability</strong>
                    <p>To the maximum extent permitted by law, Kliper shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of
                        or
                        related to your use of our services.</p>
                </section>

                <section>
                    <strong>9. Indemnification</strong>
                    <p>You agree to indemnify, defend, and hold harmless Kliper and its affiliates, officers, directors, employees, and agents from and against any claims,
                        liabilities,
                        damages, losses, and expenses arising out of your use of our services or violation of these Terms of Use.</p>
                </section>

                <section>
                    <strong>10. Governing Law</strong>
                    <p>These Terms of Use shall be governed by and construed in accordance with the laws of [Jurisdiction].</p>
                </section>

                <section>
                    <strong>11. Changes to Terms of Use</strong>
                    <p>We reserve the right to modify these Terms of Use at any time. Your continued use of our services after any changes constitute your acceptance of the updated
                        Terms.</p>
                </section>

                <p>If you have any questions or concerns about these Terms of Use or our services, please contact us at <a
                    href="mailto:admin@kliper.app">alon.shoshani@gmail.com</a>.</p>
            </div>
        </Container>
)
}

export default TermsOfUse

import { Link, Navigate, Outlet, ScrollRestoration } from 'react-router-dom'
import { useAuth } from '../hooks/AuthProvider'
import { Breadcrumb, Button, Layout as ALayout, Menu, theme } from 'antd'
import {
    CalendarOutlined,
    DesktopOutlined,
    FileOutlined,
    LogoutOutlined,
    PieChartOutlined,
    SettingOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import * as S from './styles'
import { useTranslation } from 'react-i18next'

const { Header, Content, Footer, Sider } = ALayout

const ProtectedLayout = () => {
    const { t } = useTranslation()
    const { user, logout } = useAuth()
    const [collapsed, setCollapsed] = useState(false)
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken()

    if (!user) {
        return <Navigate to="/" />
    }

    return (
        <S.DLayout>
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                theme={'light'}
                className={'sidebar'}
                width={'200px'}
            >
                <S.Logo>Kliper</S.Logo>
                <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '95%',
                    }}
                >
                    <Menu.Item key="1">
                        <DesktopOutlined />
                        <span>{t('Dashboard')}</span>
                        <Link to="/dashboard" />
                    </Menu.Item>
                    <Menu.Item key="2">
                        <CalendarOutlined />
                        <span>{t('Planner')}</span>
                        <Link to="/dashboard/planner" />
                    </Menu.Item>
                    <Menu.Item key="3">
                        <UserOutlined />
                        <span>{t('Assets')}</span>
                        <Link to="/dashboard/Assets" />
                    </Menu.Item>
                    <Menu.Item
                        key="bottom-spacer"
                        disabled
                        style={{ marginTop: 'auto', display: 'hidden' }}
                    />
                    <Menu.Item key="4">
                        <SettingOutlined />
                        <span>{t('Settings')}</span>
                        <Link to="/dashboard/settings" />
                    </Menu.Item>
                    <Menu.Item key="5" onClick={logout}>
                        <LogoutOutlined />
                        <span>{t('Logout')}</span>
                    </Menu.Item>
                </Menu>
            </Sider>
            <ALayout>
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                    }}
                >
                    <Button
                        type="text"
                        icon={
                            collapsed ? (
                                <MenuUnfoldOutlined />
                            ) : (
                                <MenuFoldOutlined />
                            )
                        }
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Breadcrumb
                        style={{
                            margin: '4px 0',
                        }}
                    >
                        {/*<Breadcrumb.Item>Dashboard</Breadcrumb.Item>*/}
                    </Breadcrumb>
                    <Outlet />
                </Content>
            </ALayout>
        </S.DLayout>
    )
}

export default ProtectedLayout

import styled from 'styled-components'

export const LoginPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;

    .facebook-login-btn {
        background: none;
        color: #000;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }
`

export const LoginFormContainer = styled.div``
export const SocialLoginContainer = styled.div`
    display: flex;
    gap: 2rem;
    padding: 2rem;

    div {
        cursor: pointer;
    }
`

export const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const FormTitle = styled.h3`
    margin-bottom: 20px;
    color: #333333;
`

export const FormGroup = styled.div`
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
`

export const Label = styled.label`
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555555;
`

export const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 16px;

    &:focus {
        outline: none;
        border-color: #6c63ff;
        box-shadow: 0 0 5px rgba(108, 99, 255, 0.3);
    }
`

export const SubmitButton = styled.button`
    padding: 10px 20px;
    background-color: #6c63ff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #5a51d4;
    }
`

import { useQuery } from '@tanstack/react-query'
import { fetchPosts } from './posts'

// export type TGithubUser = {
//   name: string;
//   bio: string;
// };

export const fetchAccount = async (accountId) => {
    const res = await fetch(
        `${process.env.REACT_APP_BACKEND_PREFIX_URL}/account/${accountId}`
    )
    if (!res.ok) {
        throw new Error('Network response was not ok')
    }
    return await res.json()
}

export const useAccount = (accountId) => {
    return useQuery({
        queryKey: ['accounts', accountId],
        queryFn: () => fetchAccount(accountId),
    })
}

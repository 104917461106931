import React from 'react'

import SvgIcon from '../../../common/SvgIcon'
import moment from 'moment-timezone'
import { PostWrapper } from './styles'

const Post = ({ post }) => {
    return (
        <PostWrapper key={post.id}>
            <div
                style={{
                    display: 'flex',
                    fontSize: '14px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        fontSize: '13px',
                        gap: '4px',
                    }}
                >
                    <SvgIcon
                        width={'18px'}
                        src={`social/${post.type}.svg`}
                        alt={post.type}
                    />
                    <div style={{ flex: 1, minWidth: 0 }}>
                        {post.asset.name}
                    </div>
                    <div>
                        {moment(post.scheduled_at).format('MMMM Do, h:mm')}
                    </div>
                </div>
            </div>
            <div>{post.details.content}</div>
        </PostWrapper>
    )
}

export default Post

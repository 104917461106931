import { styled as astyles, useTheme } from '@mui/material/styles'
import styled from 'styled-components'

export const drawerWidth = 360

export const Main = astyles('main')(({ theme, open, anchor }) => ({
    fontFamily: 'inherit !important',
    flexGrow: 1,
    //padding: theme.spacing( 3 ),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    marginLeft: 0,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        ...(anchor === 'right'
            ? { marginRight: drawerWidth }
            : { marginLeft: drawerWidth }),
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: 'relative',
    height: '100%',

    '.Kalend__main *': {
        fontFamily: 'inherit !important',
    },

    '.Kalend__CalendarHeader-tall, .Kalend__CalendarBody': {
        backgroundColor: 'white',
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
    },

    '.Kalend__MonthView__container': {
        backgroundColor: 'white',
    },

    '.Kalend__CalendarHeader-tall': {
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: '0',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
    },

    '.Kalend__CalendarBody': {
        borderBottomRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        borderTop: '1px solid #e0e0e0',
    },

    '.Kalend__CalendarBodyHours__wrapper': {
        borderRight: '1px solid #e0e0e0',
    },

    '.Kalend__CalendarHeaderCol': {
        paddingTop: '8px',
    },

    '.Kalend__CalendarHeader-small': {
        backgroundColor: 'white',
        borderBottom: '1px solid #e0e0e0',
        boxShadow: 'none',
    },

    '.Kalend__CalendarHeaderDates__circle': {
        fontSize: '1.3em',
    },
    '.Kalend__text.Kalend__CalendarBodyHours__line': {
        background: 'none !important',
    },
    '.Kalend__button.Kalend__Event-normal.Kalend__ButtonBase': {
        width: '100% !important',
        position: 'initial !important',
        height: 'auto !important',
    },
}))

export const DrawerContent = astyles('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    width: drawerWidth,
}))
export const LoadingMask = astyles('div')(({ theme }) => ({
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    zIndex: '999',
    width: '100%',
    height: '91%',
    top: '70px',
    /* margin: 6rem 0 0 0; */
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

export const DrawerTitle = astyles('div')(({ theme, status }) => ({
    fontSize: '1.5em',
    padding: theme.spacing(2),
    color:
        status === 'scheduled'
            ? '#ff825c'
            : status === 'draft'
              ? 'gray'
              : 'green',
    // backgroundColor:
    //     status === 'scheduled'
    //         ? '#ffedc6'
    //         : status === 'draft'
    //           ? 'lightgray'
    //           : '#bcd4bd',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'space-between',
}))
export const ActionsContainer = astyles('div')(({ theme, status }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',

    div: {
        display: 'flex',
        gap: '30px',
        alignItems: 'center',
        padding: '8px',
        backgroundColor: 'lightgray',
        borderRadius: '30px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'gray',
            cursor: 'pointer',
        },
    },
}))

export const PlatformWrapper = astyles('div')(({ theme, status }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    margin: '8px 0',
}))

export const DetailsWrapper = astyles('div')(({ theme, status }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    margin: '20px 0',
}))

export const EventWrapper = astyles('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // maxHeight: '270px',
    padding: '8px',
    width: '100%',

    img: {},

    span: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        fontSize: '12px',
    },
}))

export const MonthViewEventWrapper = astyles('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // maxHeight: '270px',
    padding: '2px',
    width: '100%',

    img: {},

    span: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        fontSize: '12px',
    },
}))

export const PostImg = styled.div`
    margin-top: 4px;
    background-image: url('${(props) => props.url}');
    background-color: rgb(253, 253, 253);
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 200px;
`
export const PostHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;

    div {
        font-size: 12px;
        min-width: 0;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:first-child {
            flex: 1;
        }
    }
`

export const MonthViewPostHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px;

    div {
        font-size: 11px;
        min-width: 0;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:first-child {
            flex: 1;
        }
    }
`

export const PostBody = styled.div`
    padding: 4px 0;
    width: 100%;

    div {
        font-size: 12px;
        -webkit-line-clamp: 1;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`

export const MonthViewPostBody = styled.div`
    padding: 4px 0;
    width: 100%;

    div {
        display: flex;
        gap: 4px;
        font-size: 12px;
        -webkit-line-clamp: 1;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`

import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import * as S from './styles'
import SvgIcon from '../../SvgIcon'
import FacebookLogin from 'react-facebook-login'
import TikTokLogin from '../../../Integrations/tiktok'

const CreateIntegration = () => {
    const responseFacebook = (e) => {
        console.log(e)
    }

    return (
        <>
            <S.Container>
                <S.Integration>
                    <SvgIcon
                        width={'40px'}
                        src="social/facebook.svg"
                        alt={'facebook'}
                    />
                    <span>Facebook</span>
                </S.Integration>
                <S.Integration>
                    <SvgIcon
                        width={'30px'}
                        src="social/instagram.svg"
                        alt={'instagram'}
                    />
                    <span>Instagram</span>
                </S.Integration>
                <TikTokLogin>
                    <S.Integration>
                        <SvgIcon
                            width={'30px'}
                            src="social/tik-tok.png"
                            alt={'TikTok'}
                        />
                        <span>TikTok</span>
                    </S.Integration>
                </TikTokLogin>
                <S.Integration>
                    <SvgIcon
                        width={'30px'}
                        src="social/linkedin.png"
                        alt={'LinkedIn'}
                    />
                    <span>LinkedIn</span>
                </S.Integration>
                <S.Integration>
                    <SvgIcon
                        width={'30px'}
                        src="social/youtube.png"
                        alt={'YouTube'}
                    />
                    <span>YouTube</span>
                </S.Integration>
                <S.Integration>
                    <SvgIcon
                        width={'30px'}
                        src="social/pinterest.png"
                        alt={'Pinterest'}
                    />
                    <span>Pinterest</span>
                </S.Integration>
                <S.Integration>
                    <SvgIcon
                        width={'40px'}
                        src="social/twitter.png"
                        alt={'twitter'}
                    />
                    <span>Twitter</span>
                </S.Integration>

                {/*<FacebookLogin*/}
                {/*    appId="722597069856816"*/}
                {/*    autoLoad={ false }*/}
                {/*    fields="name,email,picture"*/}
                {/*    callback={ responseFacebook }*/}
                {/*    scope="pages_manage_engagement,pages_manage_metadata,pages_manage_posts,pages_read_engagement,pages_read_user_engagement,pages_show_list,publish_video"*/}
                {/*/>*/}
            </S.Container>
        </>
    )
}
export default CreateIntegration

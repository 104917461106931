import styled from 'styled-components'

export const FacebookPostContainer = styled.div`
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
    width: 100%;
    max-width: 400px;

    .post-header {
        display: flex;
        align-items: center;
    }

    .profile-picture {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .author-info {
        display: flex;
        flex-direction: column;
    }

    .author-name {
        margin: 0 10px;
        font-size: 16px;
        font-weight: 700;
        color: rgb(36, 31, 33);
        font-family: inherit;
    }

    .post-date {
        margin: 0 10px;
        color: #888;
        font-size: 14px;
    }

    .post-content {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: rgb(36, 31, 33);
        margin-top: 0;
    }

    .post-attachment img {
        object-fit: cover;
        width: 100%;
    }

    .post-actions {
        margin-top: 10px;
        flex: 0 0 auto;
        display: flex;
        justify-content: center;
        height: 40px;
        line-height: 48px;

        img {
            margin: 0 6px;
        }

        div {
            font-size: 13px;
            font-weight: bold;
            color: rgb(96, 103, 112);
            display: flex;
            width: 90px;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;

            &:last-child {
                margin-right: 0;
            }

            span {
                margin-left: 6px;
            }
        }
    }

    .post-actions button {
        margin-right: 10px;
    }
`

import styled from 'styled-components'
import { MenuOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

export const Header = styled.header`
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    backdrop-filter: blur(16px);
    z-index: 1000;
    padding: 1rem;
    direction: ltr;
`

export const LogoContainer = styled(Link)`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 1.5rem;
`

export const Container = styled.div`
    flex: 1;
    position: relative;
    width: 100%;
    max-width: 1280px;
    padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    margin-left: auto;
    font-family: inherit !important;
`

export const NavLink = styled.div`
    display: inline-block;
    text-align: center;
`

export const CustomNavLink = styled.div`
    width: 203px;
    display: inline-block;

    @media only screen and (max-width: 411px) {
        width: 150px;
    }

    @media only screen and (max-width: 320px) {
        width: 118px;
    }
`

export const ContactWrapper = styled.div`
    cursor: pointer;
    width: ${(props) => (props.width ? '100%' : '110px')};
    font-weight: 700;
    text-align: center;
    border-radius: 1.25rem;
    display: inline-block;
`

export const Burger = styled.div`
    @media only screen and (max-width: 768px) {
        display: block !important;
    }
    padding: 1.25rem 1.25rem;
    display: none;
`

export const NotHidden = styled.div`
    @media only screen and (max-width: 768px) {
        display: none;
    }
`

export const Menu = styled.h5`
    font-size: 1.37rem;
    margin-top: -0.45rem;
    padding: 0 1.56rem 0 0;
    font-weight: 600;
    border-bottom: 5px solid #111b47;
`

export const CustomNavLinkSmall = styled(NavLink)`
    font-size: 1rem;
    color: #000000;
    transition: color 0.2s ease-in;
    margin: 0.25rem 2rem;
    font-family: 'Ubuntu', sans-serif;
    padding: 1.5rem 0.5rem;

    @media only screen and (max-width: 768px) {
        margin: 1.25rem 2rem;
    }
`
export const CustomNavLinkSmallBorder = styled(NavLink)`
    font-size: 1rem;
    color: #000000;
    transition: color 0.2s ease-in;
    margin: 0.25rem 2rem;
    font-family: 'Ubuntu', sans-serif;
    padding: 0.5rem 1.5rem;
    background: #9da6e1;
    border-radius: 20px;

    @media only screen and (max-width: 768px) {
        margin: 1.25rem 2rem;
    }
`

export const Label = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: #404041;
    text-align: right;
    display: flex;
    align-items: center;
`

export const Outline = styled(MenuOutlined)`
    font-size: 22px;
    padding-right: ${(props) => (props.padding ? '10px' : '')};
`

export const Span = styled.span`
    cursor: pointer;
`

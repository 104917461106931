import React, { useEffect, useState } from 'react'
import moment from 'moment-timezone'
import { Drawer } from '@material-ui/core'
import 'kalend/dist/styles/index.css'
import translationHe from '../../locales/he/he.json'
import { motion } from 'framer-motion'

import {
    ActionsContainer,
    DetailsWrapper,
    DrawerContent,
    DrawerTitle,
    drawerWidth,
    EventWrapper,
    Flex,
    LoadingMask,
    Main,
    MonthViewEventWrapper,
    MonthViewPostBody,
    MonthViewPostHeader,
    PlatformWrapper,
    PostBody,
    PostHeader,
    PostImg,
} from './styles'
import { Button, Form, Modal, Spin } from 'antd'
import * as S from '../Dashboard/styles'
import CreatePost from '../../common/Modal/CreatePost'
import SvgIcon from '../../common/SvgIcon'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { postPost, usePosts } from '../../api/posts'
import {
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    LoadingOutlined,
} from '@ant-design/icons'
import Facebook from '../../common/Modal/CreatePost/facebook'
import { useAuth } from '../../hooks/AuthProvider'
import { useTranslation } from 'react-i18next'
import Kalend, { CalendarView } from '../../common/Kalend'
import PostAttachment from '../../common/PostAttachment'

const Planner = () => {
    const { t, i18n } = useTranslation()
    const { user } = useAuth()
    const [selectedDate, setSelectedDate] = useState()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [drawerData, setDrawerData] = useState()
    const [events, setEvents] = useState([])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const [isEditMode, setIsEditMode] = useState(false)

    const queryClient = useQueryClient()

    const { isLoading, error, data: accounts } = usePosts(user.accountId)

    const addPost = useMutation({
        mutationFn: postPost,
        mutationKey: 'postPost',
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['posts'] })
            form.resetFields()
        },
    })

    const deletePost = async () => {
        setLoading(true)
        try {
            await axios.delete(
                `${process.env.REACT_APP_BACKEND_PREFIX_URL}/posts/${drawerData.id}`
            )
            queryClient.invalidateQueries({ queryKey: ['posts'] })
            setDrawerOpen(false)
        } catch (error) {
            console.error('Mutation failed:', error)
        } finally {
            setLoading(false)
        }
    }

    const editPost = async () => {
        setDrawerOpen(false)
        form.setFieldsValue({
            content: drawerData.content,
            time: moment(drawerData.scheduled_at),
            accounts: [accounts.find((x) => x.name === drawerData.author).id],
            upload: drawerData.attachments.map((url) => ({ url })), // Assuming this is the format of attachments
        })
        setIsEditMode(true)
        setOpen(true)
    }

    const onSubmit = async (event, draft = false) => {
        try {
            event.preventDefault()
            const a = await form.validateFields()
            setLoading(true)
            const attachments = form.getFieldValue('upload') //?.map((img) => img)
            const request = {
                details: {
                    content: form.getFieldValue('content'),
                    ...(attachments?.length > 0 ? { attachments } : {}),
                },
                scheduled_at: (form.getFieldValue('time') || moment()).format(),
                asset_id: form.getFieldValue('accounts')[0],
                status: draft ? 'draft' : 'scheduled',
            }

            if (isEditMode) {
                await axios.put(
                    `${process.env.REACT_APP_BACKEND_PREFIX_URL}/posts/${drawerData.id}`,
                    request
                )
            } else {
                await addPost.mutateAsync({ data: request })
            }

            queryClient.invalidateQueries({ queryKey: ['posts'] })
            setLoading(false)
            setOpen(false)
            form.resetFields() // Reset the form after successful submission
            setIsEditMode(false)
        } catch (error) {
            console.error('Mutation failed:', error)
        }
    }

    const Event = ({ post, client }) => {
        return (
            <EventWrapper>
                <SvgIcon
                    width={'24px'}
                    src={`social/${post.type}.svg`}
                    alt={post.type}
                />
                {post.details.attachments[0] && (
                    <PostAttachment url={post.details.attachments[0]} />
                )}
                <PostHeader>
                    <div>{client.name}</div>
                    <div>{moment(post.scheduled_at).format('h:mm a')}</div>
                </PostHeader>
                <PostBody>
                    <div>{post.details.content}</div>
                    {post.status === 'published' ? (
                        <div style={{ color: 'green', marginTop: '8px' }}>
                            {t('Published')}
                        </div>
                    ) : null}
                    {post.status === 'draft' ? (
                        <div style={{ color: 'gray', marginTop: '8px' }}>
                            {t('Draft')}
                        </div>
                    ) : null}
                    {post.status === 'scheduled' ? (
                        <div style={{ color: '#ff825c', marginTop: '8px' }}>
                            {t('Scheduled')}
                        </div>
                    ) : null}
                </PostBody>
            </EventWrapper>
        )
    }
    const MonthViewEvent = ({ post, client }) => {
        return (
            <MonthViewEventWrapper>
                <MonthViewPostHeader>
                    <Flex>
                        <SvgIcon
                            width={'14px'}
                            src={`social/${post.type}.svg`}
                            alt={post.type}
                        />
                        {client.name}
                    </Flex>
                    <div>{moment(post.scheduled_at).format('h:mm')}</div>
                </MonthViewPostHeader>
                <MonthViewPostBody>
                    <div>
                        {post.details.attachments[0] && (
                            <img
                                src={post.details.attachments[0]}
                                height={'30px'}
                                alt={post.details.attachments[0]}
                            />
                        )}
                        {post.details.content}
                    </div>
                </MonthViewPostBody>
            </MonthViewEventWrapper>
        )
    }

    const convertEvents = (data) => {
        const posts = []

        data.forEach((client) => {
            client.posts.forEach((post) => {
                const event = post.details

                if (!event) return

                // if ( event.allDay ) {
                //     return {
                //         ...event,
                //         ...{ color: '#eaeaea' },
                //
                //     }
                // }

                posts.push({
                    id: post.id,
                    startAt: post.scheduled_at,
                    endAt: post.scheduled_at,
                    type: post.type,
                    content: post.details.content,
                    status: post.status,
                    author: client.name,
                    attachments: post.details.attachments,
                    ...{
                        children: {
                            agendaView: <Event post={post} client={client} />,
                            daysView: <Event post={post} client={client} />,
                            monthView: (
                                <MonthViewEvent post={post} client={client} />
                            ),
                        },
                        color:
                            event.status === 'published'
                                ? '#b9dbb1'
                                : '#eaeaea',
                    },
                })
            })
        })

        return posts
    }

    useEffect(() => {
        if (accounts) {
            setEvents(convertEvents(accounts) || [])
        }
    }, [accounts])

    if (error) return 'An error has occurred: ' + error.message

    const onNewEventClick = (data) => {
        console.log(data)
        const msg = `New event click action\n\n Callback data:\n\n${JSON.stringify(
            {
                hour: data.hour,
                day: data.day,
                startAt: data.startAt,
                endAt: data.endAt,
                view: data.view,
                event: 'click event ',
            }
        )}`
        const newEvent = {
            id: 1,
            startAt: '2024-05-08T18:00:00.000Z',
            endAt: '2024-05-08T19:00:00.000Z',
            timezoneStartAt: 'Europe/Berlin', // optional
            summary: 'test',
            children: {
                agendaView: (
                    <SvgIcon
                        width={'20px'}
                        src="social/facebook.svg"
                        alt={'facebook'}
                    />
                ),
                daysView: (
                    <SvgIcon
                        width={'20px'}
                        src="social/facebook.svg"
                        alt={'facebook'}
                    />
                ),
                monthView: (
                    <SvgIcon
                        width={'20px'}
                        src="social/facebook.svg"
                        alt={'facebook'}
                    />
                ),
            },
            color: 'blue',
            calendarID: 'work',
        }
        setSelectedDate(new Date(data.startAt))
        form.resetFields()
        setIsEditMode(false)
        setOpen(true)
    }

    // Callback for event click
    const onEventClick = (data) => {
        setDrawerOpen(true)
        setDrawerData(data)
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }

    const today = new Date()

    return (
        <Main style={{ height: '100%' }}>
            <S.Button
                onClick={() => {
                    form.resetFields()
                    setOpen(true)
                    setSelectedDate(null)
                }}
            >
                {t('Create')}
            </S.Button>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        backgroundColor: '#f5f4f1',
                    },
                }}
                open={drawerOpen}
                onClose={handleDrawerClose}
                anchor={i18n.language === 'he' ? 'left' : 'right'}
                variant="persistent"
                title={'New Post'}
            >
                {/* Drawer content goes here */}
                <DrawerTitle status={drawerData?.status}>
                    <div>{t(drawerData?.status)}</div>
                    <CloseOutlined onClick={handleDrawerClose} />
                </DrawerTitle>
                <DrawerContent>
                    <ActionsContainer style={{ display: 'flex' }}>
                        <Button
                            type="default"
                            htmlType="submit"
                            onClick={editPost}
                            disabled={loading}
                            icon={<EditOutlined />}
                        >
                            {t('Edit')}
                        </Button>
                        <Button
                            type="default"
                            htmlType="submit"
                            onClick={deletePost}
                            disabled={loading}
                            icon={<DeleteOutlined />}
                        >
                            {t('Delete')}
                        </Button>
                    </ActionsContainer>
                    <PlatformWrapper>
                        <SvgIcon
                            width={'30px'}
                            src={`social/${drawerData?.type}.svg`}
                            alt={`${drawerData?.type}`}
                        />
                        <div>{t(drawerData?.type)}</div>
                    </PlatformWrapper>
                    <Facebook
                        text={drawerData?.content}
                        author={drawerData?.author}
                        date={moment(drawerData?.startAt).format('h:mm a')}
                        attachment={drawerData?.attachments[0]}
                    />
                    <DetailsWrapper>
                        <div>
                            <b>{t('Publish date:')}</b>{' '}
                            {moment(drawerData?.startAt).format(
                                'MMMM Do YYYY, h:mm a'
                            )}
                        </div>
                        <div>
                            <b>{t('Post link:')}</b> {drawerData?.link || 'N/A'}
                        </div>
                    </DetailsWrapper>
                </DrawerContent>
            </Drawer>

            <Main
                open={drawerOpen}
                anchor={i18n.language === 'he' ? 'left' : 'right'}
            >
                {loading ||
                    (isLoading && (
                        <LoadingMask>
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{ fontSize: '5rem' }}
                                        spin
                                    />
                                }
                            />
                        </LoadingMask>
                    ))}

                <Kalend
                    //kalendRef={ props.kalendRef }
                    //draggingDisabledConditions={false}
                    onNewEventClick={onNewEventClick}
                    initialView={CalendarView.WEEK}
                    disabledViews={[CalendarView.DAY]}
                    onEventClick={onEventClick}
                    events={events}
                    initialDate={new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate(),
                        1,
                        0,
                        0
                    ).toISOString()}
                    //hourHeight={ 60 }
                    // showWeekNumbers={true}
                    // draggingDisabledConditions={{
                    //   summary: 'Computers',
                    //   allDay: false,
                    //   color: 'pink',
                    // }}
                    // onStateChange={ onStateChange }
                    // selectedView={ selectedView }
                    showTimeLine={false}
                    isDark={false}
                    autoScroll={true}
                    //timeFormat={ '12' }
                    // weekDayStart={i18n.language === 'he' ? 'Sunday' : 'Monday'}
                    // {...(i18n.language !== 'he' && { language: i18n.language })}
                    // {...(i18n.language === 'he' && {
                    //     customLanguage: translationHe,
                    //     language: 'customLanguage',
                    // })}
                />
            </Main>

            <Modal
                title={t('New Post')}
                centered
                open={open}
                onOk={onSubmit}
                onCancel={() => setOpen(false)}
                width={'90%'}
                footer={
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <div
                            style={{
                                display: 'flex',
                                gap: '8px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                type="default"
                                onClick={() => setOpen(false)}
                            >
                                {t('Cancel')}
                            </Button>
                            {!isEditMode && (
                                <Button
                                    type="default"
                                    htmlType="submit"
                                    onClick={(e) => onSubmit(e, true)}
                                    disabled={loading}
                                >
                                    {t('Finish later')}
                                </Button>
                            )}
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={onSubmit}
                                disabled={loading}
                            >
                                {t(isEditMode ? 'Update' : 'Create')}
                            </Button>
                        </div>
                    </Form.Item>
                }
            >
                <Form id="myForm" form={form}>
                    <CreatePost
                        accounts={accounts}
                        form={form}
                        selectedDate={selectedDate}
                        isEditMode={isEditMode}
                        open={open}
                    />
                </Form>
            </Modal>
        </Main>
    )
}

export default Planner

import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import XHR from 'i18next-xhr-backend'

import translationEn from './locales/en/translation.json'
import translationEs from './locales/es/translation.json'
import translationHe from './locales/he/translation.json'

import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/he'

i18n.use(XHR)
    .use(detector)
    .init({
        debug: false,
        fallbackLng: 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['cookie', 'localStorage', 'sessionStorage', 'navigator'],
            cache: ['cookie'],
            lookupCookie: 'language',
        },
        resources: {
            en: {
                translations: translationEn,
            },
            es: {
                translations: translationEs,
            },
            he: {
                translations: translationHe,
            },
        },
        ns: ['translations'],
        defaultNS: 'translations',
    })

export default i18n

import React, { useEffect, useState } from 'react'
import { Button, Modal, Select, Input, Form, DatePicker, Switch } from 'antd'
import * as S from './styles'
import FacebookPost from './facebook'
import ImgUpload from '../../ImgUpload'
import AdobeEditor from '../../AbobeEditor'
import DynamicImageUpload from '../../ImgUpload'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import SvgIcon from '../../SvgIcon'

const { TextArea } = Input

const CreatePost = ({ accounts, form, selectedDate, open }) => {
    const { t } = useTranslation()
    const [showAdobe, setShowAdobe] = useState(false)
    const [img, setImg] = useState(null)
    const [test, setTest] = useState(null)
    const [now, setNow] = useState(form.getFieldValue('time'))

    if (selectedDate) {
        form.setFieldValue('time', selectedDate)
    }

    const allValues = Form.useWatch([], form)

    const options = accounts?.map((asset) => ({
        value: asset.id,
        label: asset.name,
    }))
    const handleChange = (value) => {
        console.log(`Selected: ${value}`)
    }

    const attachmentsUpdate = (value) => {
        setTest(value)
        form.setFieldValue('upload', value)
    }

    const onPostTextChange = (e) => {
        setTest('value')
        form.setFieldValue('content', e.target.value)
    }

    const onNowChange = (checked) => {
        setNow(checked)
        if (checked) {
            form.setFieldValue('time', moment())
        }
    }

    const onPublish = (e) => {
        setShowAdobe(false)
        setImg(e.image)
    }
    const handleNow = (e) => {
        if (e) {
            form.setFieldValue('time', e)
        } else {
            form.setFieldValue('time', moment())
        }
    }

    return (
        <>
            <S.Container>
                <S.ContainerLeft>
                    <div>{t('Publish to:')}</div>
                    <div>
                        <Form.Item name="accounts">
                            <Select
                                mode="multiple"
                                placeholder={t('Please select')}
                                defaultValue={form.getFieldValue('accounts')}
                                value={form.getFieldValue('accounts')}
                                onChange={handleChange}
                                style={{ width: '100%' }}
                                options={options}
                                rules={[
                                    {
                                        required: true,
                                        // type: 'array',
                                        message:
                                            'Please select at least one account',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </div>

                    <div>{t('Content')}:</div>
                    <div>
                        <Form.Item name="content" required={true}>
                            <TextArea
                                onChange={onPostTextChange}
                                placeholder={t('Post content')}
                                style={{ height: 120, resize: 'none' }}
                                defaultValue={form.getFieldValue('content')}
                                value={form.getFieldValue('content')}
                            />

                            {showAdobe && <AdobeEditor onPublish={onPublish} />}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                    fontWeight: 500,
                                }}
                            >
                                <S.AdobeButton
                                    onClick={() => setShowAdobe(true)}
                                    disabled={showAdobe}
                                >
                                    <SvgIcon
                                        // onClick={() => setShowAdobe(true)}
                                        width={'30px'}
                                        src="adobe.svg"
                                        alt={'edit with adobe'}
                                    />
                                </S.AdobeButton>
                                <span>{t('Create with Adobe Express')}</span>
                            </div>
                        </Form.Item>
                    </div>

                    <div>{t('Scheduling options:')}</div>
                    <div style={{ display: 'flex' }}>
                        <Form.Item
                            name="time"
                            style={{ display: 'flex' }}
                            rules={[
                                {
                                    required: true,
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        debugger
                                        if (
                                            value
                                            //getFieldValue('password') === value
                                        ) {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(
                                            new Error(
                                                'Please select date and time'
                                            )
                                        )
                                    },
                                }),
                            ]}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '8px',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Ubuntu',
                                        display: 'flex',
                                        gap: '8px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    {t('Now')}{' '}
                                    <Switch
                                        checked={now}
                                        onChange={onNowChange}
                                    />
                                </div>
                                <div>
                                    {!now && (
                                        <DatePicker
                                            onNow={handleNow}
                                            onChange={handleNow}
                                            placeholder={t(
                                                'Select date and time'
                                            )}
                                            showTime={{ use12Hours: true }}
                                        />
                                    )}
                                </div>
                            </div>
                        </Form.Item>
                    </div>
                    <div>
                        <DynamicImageUpload
                            initialImages={img}
                            onChange={attachmentsUpdate}
                            form={form}
                        />
                    </div>
                </S.ContainerLeft>
                <S.ContainerRight>
                    <FacebookPost
                        author={'Demo Account'}
                        content={'This is a test post'}
                        text={form.getFieldValue('content')}
                        attachment={form.getFieldValue('upload')?.[0]}
                    />
                    <S.SmallLetters>
                        {t(
                            'Social networks regularly make updates to formatting,'
                        )}
                        <br />
                        {t(
                            'so your post may appear slightly different when published.'
                        )}
                    </S.SmallLetters>
                </S.ContainerRight>
            </S.Container>
        </>
    )
}
export default CreatePost

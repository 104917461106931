import { useQuery } from '@tanstack/react-query'

// export type TGithubUser = {
//   name: string;
//   bio: string;
// };

export const postPost = async ({ data }) => {
    const res = await fetch(
        `${process.env.REACT_APP_BACKEND_PREFIX_URL}/posts/`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }
    )
    if (!res.ok) {
        throw new Error('Network response was not ok')
    }
    return await res.json()
}

export const fetchPosts = async (accountId) => {
    const res = await fetch(
        `${process.env.REACT_APP_BACKEND_PREFIX_URL}/assets/account/${accountId}`
    )
    if (!res.ok) {
        throw new Error('Network response was not ok')
    }
    return await res.json()
}

export const usePosts = (accountId) => {
    return useQuery({
        queryKey: ['posts', accountId],
        queryFn: () => fetchPosts(accountId),
    })
}

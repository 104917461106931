import React, { useState } from 'react'
import 'kalend/dist/styles/index.css' // import styles
import { Button, Modal } from 'antd'
import * as S from '../Dashboard/styles'
import CreateIntegration from '../../common/Modal/CreateIntegration'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { Account } from './styles'
import SvgIcon from '../../common/SvgIcon'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const Accounts = () => {
    const { t } = useTranslation()
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [events, setEvents] = useState([])
    const [open, setOpen] = useState(false)

    const { isPending, error, data, isFetching } = useQuery({
        queryKey: ['accounts'],
        queryFn: () => axios.get('/account/2').then((res) => res.data),
    })

    if (isPending) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message

    return (
        <S.Wrapper>
            <S.Content>
                <S.Box>
                    <S.Top>
                        <S.Header>{t('Social Integrations')}</S.Header>
                        <Button type="primary" onClick={() => setOpen(true)}>
                            {t('Connect a channel')}
                        </Button>
                    </S.Top>

                    {data?.accounts?.map((account) => {
                        return (
                            <Account>
                                <SvgIcon
                                    width={'30px'}
                                    src={`social/${account.platform}.svg`}
                                    alt={`${account.platform}`}
                                />
                                <div>{account.name}</div>
                                <div>
                                    <CloseOutlined />
                                </div>
                            </Account>
                        )
                    })}

                    {/*<TikTokLogin*/}
                    {/*    client_key={ 'awmkqq0883sl44g1' }*/}
                    {/*    redirect_uri={ REDIRECT_URI }*/}
                    {/*    //onLoginStart={onLoginStart}*/}
                    {/*    onResolve={ ( { provider, data } ) => {*/}

                    {/*        //setProvider( provider )*/}
                    {/*        //setProfile( data )*/}
                    {/*    } }*/}
                    {/*    onReject={ ( err ) => {*/}
                    {/*        console.log( err )*/}
                    {/*    } }*/}
                    {/*    className="pinterest-btn"*/}
                    {/*>*/}
                    {/*    <div className="content">*/}
                    {/*        <div className="icon">*/}
                    {/*            /!*<TiktokLogo/>*/}
                    {/*        </div>*/}
                    {/*        <span className="txt">Login With Tiktok</span>*/}
                    {/*    </div>*/}
                    {/*</TikTokLogin>*/}
                </S.Box>
            </S.Content>

            <Modal
                title={t('Add an asset')}
                centered
                open={open}
                onCancel={() => setOpen(false)}
                cancelText={t('Cancel')}
                width={1000}
            >
                <CreateIntegration />
            </Modal>
        </S.Wrapper>
    )
}

export default Accounts

import { UploadOutlined } from '@ant-design/icons'
import { Image, Upload, Form } from 'antd'
import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/AuthProvider'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

const ImgUpload = ({ initialImages, onChange }) => {
    const { t } = useTranslation()
    const { user } = useAuth()
    const [fileList, setFileList] = useState([])
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')

    useEffect(() => {
        if (initialImages) {
            const newFile = {
                uid: `-${fileList.length + 1}`,
                name: `image${fileList.length + 1}.png`,
                status: 'done',
                url: initialImages,
            }
            setFileList((prevFileList) => [...prevFileList, newFile])
            onChange([...fileList, newFile])
        }
    }, [initialImages])

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })
    }

    const beforeUpload = (file) => {
        // const isImage = file.type.startsWith( 'image/' )
        // if ( !isImage ) {
        //     console.error( 'You can only upload image files!' )
        //     return false
        // }
        // return true
        onChange([...fileList, file])
        setFileList([...fileList, file])
        return true
    }

    const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
        try {
            const config = {
                headers: { 'content-type': 'multipart/form-data' },
                onUploadProgress: (event) => {
                    console.log((event.loaded / event.total) * 100)
                    onProgress(
                        { percent: (event.loaded / event.total) * 100 },
                        file
                    )
                },
            }
            const fmData = new FormData()
            fmData.append('file', file)

            const { data } = await axios.post(
                `${process.env.REACT_APP_BACKEND_PREFIX_URL}/media/upload/${user.accountId}`,
                fmData,
                config
            )
            // const base64Data = await getBase64(file)
            // const newFile = {
            //     uid: `-${fileList.length + 1}`,
            //     name: file.name,
            //     status: 'done',
            //     url: base64Data,
            // }
            if (data?.url) {
                onChange([...fileList, data?.url])

                const base64Data = await getBase64(file)
                const newFile = {
                    uid: `-${fileList.length + 1}`,
                    name: file.name,
                    status: 'done',
                    url: base64Data,
                }
                setFileList((prevFileList) => [...prevFileList, newFile])

                onSuccess('Upload successful!')
            } else {
                onError('Upload failed!')
            }
        } catch (error) {
            onError(error)
            console.error('Upload failed.')
        }
    }

    const handleOnChange = ({ file, fileList, event }) => {
        // console.log(file, fileList, event);
        //Using Hooks to update the state to the current filelist
        // debugger
        // setFileList(fileList)
        //filelist - [{uid: "-1",url:'Some url to image'}]
    }

    const handleRemove = (file) => {
        setFileList((prevFileList) =>
            prevFileList.filter((item) => item.uid !== file.uid)
        )
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        setPreviewImage(file.url || file.preview)
        setPreviewOpen(true)
    }

    const normFile = (e) => {
        // debugger
        // if ( Array.isArray( e ) ) {
        //     return e
        // }
        // return e?.fileList
    }

    return (
        <>
            <Form.Item
                name="upload"
                //label={t('Upload')}
                getValueFromEvent={normFile}
            >
                <Upload
                    listType="picture-card"
                    label={t('Upload')}
                    fileList={fileList}
                    onRemove={handleRemove}
                    onChange={handleOnChange}
                    //beforeUpload={ beforeUpload }
                    //action='/media/upload/1'
                    customRequest={customRequest}
                    onPreview={handlePreview}
                >
                    {fileList.length < 8 && (
                        <div>
                            <UploadOutlined />
                            <div style={{ marginTop: 8 }}>{t('Upload')}</div>
                        </div>
                    )}
                </Upload>
            </Form.Item>
            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                            !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </>
    )
}

export default ImgUpload

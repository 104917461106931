import React, { useEffect } from 'react'
import Loadable from '@loadable/component'
import { Route, Routes, ScrollRestoration, useLocation } from 'react-router-dom'

import Footer from '../components/Footer'
import Header from '../components/Header'

import GlobalStyles from '../globalStyles'
import TermsOfUse from '../components/TermsOfUse'
import Login from '../components/Login'
import Planner from '../components/Planner'
import ProtectedLayout from './private'
import Dashboard from '../components/Dashboard'
import Accounts from '../components/Accounts'
import ScrollToTop from './ScrollToTop'
import Settings from '../components/Settings'
import { useTranslation } from 'react-i18next'
import NotFoundPage from '../pages/404'
import moment from 'moment/moment'
import Callback from '../components/Callback/Callback'
import { useAuth } from '../hooks/AuthProvider'

const Home = Loadable(() => import(`../components/Home`))
const PrivacyPolicy = Loadable(() => import(`../components/PrivacyPolicy`))

const MainRouter = () => {
    const { user } = useAuth()
    const location = useLocation()
    const { t, i18n } = useTranslation()
    moment.locale(i18n.language)

    useEffect(() => {
        const fetchToken = async () => {
            const urlParams = new URLSearchParams(window.location.search)
            const code = urlParams.get('code')

            if (code) {
                const response = await fetch(
                    `${process.env.REACT_APP_BACKEND_PREFIX_URL}/oauth/tiktok`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        method: 'POST',
                        body: JSON.stringify({
                            account_id: user.accountId,
                            code: code,
                        }),
                    }
                )
            }
        }

        fetchToken()
    }, [location.search])

    return (
        <>
            <GlobalStyles direction={i18n.language === 'he' ? 'rtl' : 'ltr'} />
            <Header />
            {/*<Suspense fallback={ <div>Loading...</div> }>*/}
            {/*<ScrollToTop />*/}
            <Routes element={ScrollRestoration}>
                <Route path="/" element={<Home />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/login" element={<Login />} />
                {/*<Route path="/register" element={ <Register/> }/>*/}
                <Route path="/dashboard" element={<ProtectedLayout />}>
                    <Route path="" element={<Dashboard />} />
                    <Route path="planner" element={<Planner />} />
                    <Route path="assets" element={<Accounts />} />
                    <Route path="settings" element={<Settings />} />
                </Route>
                <Route path="/auth/callback" component={Callback} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <Footer />
            {/*</Suspense>*/}
        </>
    )
}

export default MainRouter

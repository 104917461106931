import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage('user', null)
    const navigate = useNavigate()

    // call this function when you want to authenticate the user
    const login = async (data) => {
        if (data.google) {
            try {
                const rawResponse = await fetch(
                    `${process.env.REACT_APP_BACKEND_PREFIX_URL}/account/google-login`,
                    {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            email: data?.email,
                            name: data?.name,
                        }),
                    }
                )
                const content = await rawResponse.json()
                setUser({ ...data, accountId: content.id })
            } catch (e) {
                console.log(e)
                return e
            }
        } else {
            //if (data.username !== 'test@kliper.app') {
            setUser({
                email: 'test@kliper.app',
                accountId: 8,
                name: 'Demo',
            })
            //}
        }
        navigate('/dashboard')
    }

    // call this function to sign out logged in user
    const logout = () => {
        setUser(null)
        navigate('/', { replace: true })
    }

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
        }),
        [user]
    )
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider

export const useAuth = () => {
    return useContext(AuthContext)
}

import React, { useState } from 'react'
import 'kalend/dist/styles/index.css'
import TikTokLogin from '../../Integrations/tiktok' // import styles
import { Button, Col, Layout, Modal, Row, Statistic, theme } from 'antd'
import { useAuth } from '../../hooks/AuthProvider'
import * as S from './styles'
import CreateIntegration from '../../common/Modal/CreateIntegration'
import { withTranslation } from 'react-i18next'
import { usePosts } from '../../api/posts'
import SvgIcon, { SocialIcon } from '../../common/SvgIcon'
import moment from 'moment-timezone'
import { useAccount } from '../../api/account'
import Post from './Post'
import CountUp from 'react-countup'
import { motion } from 'framer-motion'

const Dashboard = ({ t }) => {
    const { user } = useAuth()
    const [open, setOpen] = useState(false)
    const {
        isLoading: postsLoading,
        error: postsError,
        data: postsData,
    } = usePosts(user.accountId)
    const {
        isLoading: accountsLoading,
        error: accountsError,
        data: accounts,
    } = useAccount(user.accountId)

    const posts = postsData
        ?.map((asset) => {
            return [
                ...asset?.posts.map((post) => {
                    return { ...post, asset: asset }
                }),
            ]
        })
        .flat()

    const error = postsError || accountsError
    const loading = postsLoading || accountsLoading

    const formatter = (value) => <CountUp end={value} separator="," />

    return (
        <S.Wrapper>
            <S.Top>
                <S.Title>
                    {t('Welcome')}, {user.name}
                </S.Title>
            </S.Top>

            <Row style={{ margin: '20px 0' }}>
                <Col span={12}>
                    <S.Box style={{ marginRight: '8px' }}>
                        <Statistic
                            title="Total posts"
                            value={posts?.length}
                            formatter={formatter}
                        />
                    </S.Box>
                </Col>
                <Col span={12}>
                    <S.Box style={{ marginLeft: '8px' }}>
                        <Statistic
                            title="Feedback"
                            value={123}
                            precision={2}
                            formatter={formatter}
                        />
                    </S.Box>
                </Col>
            </Row>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <S.Content>
                    <S.Box>
                        <S.Top>
                            <S.Header>{t('Channels')}</S.Header>
                            <Button
                                type="primary"
                                onClick={() => setOpen(true)}
                            >
                                {t('Connect a channel')}
                            </Button>
                        </S.Top>
                        <span>
                            {error &&
                                t(
                                    'Some error occurred, please try again later'
                                )}
                        </span>
                        {loading ? (
                            <span>{t('Loading')}</span>
                        ) : (
                            <S.PostsContainer>
                                {accounts?.integrations?.map((t) => {
                                    return (
                                        <S.Asset className="logo" key={t.id}>
                                            <SocialIcon
                                                width={'24px'}
                                                type={t.type}
                                            />
                                            <div>{t.name}</div>
                                        </S.Asset>
                                    )
                                })}
                            </S.PostsContainer>
                        )}
                    </S.Box>

                    <S.Box>
                        <S.Header>{t('Your drafts')}</S.Header>

                        <span>
                            {error &&
                                t(
                                    'Some error occurred, please try again later'
                                )}
                        </span>
                        {loading ? (
                            <span>{t('Loading')}</span>
                        ) : (
                            <S.PostsContainer>
                                {posts
                                    ?.filter((x) => x.status === 'draft')
                                    .map((post) => {
                                        return (
                                            <Post key={post.id} post={post} />
                                        )
                                    })}
                            </S.PostsContainer>
                        )}
                    </S.Box>

                    <S.Box>
                        <S.Header>{t('Next posts to be published')}</S.Header>
                        <span>{loading && t('Loading')}</span>
                        <span>
                            {error &&
                                t(
                                    'Some error occurred, please try again later'
                                )}
                        </span>
                        <S.PostsContainer>
                            {posts
                                ?.filter((x) => x.status === 'scheduled')
                                .map((post) => {
                                    return <Post key={post.id} post={post} />
                                })}
                        </S.PostsContainer>
                    </S.Box>
                </S.Content>
            </motion.div>

            <Modal
                title={t('Add an asset')}
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={1000}
            >
                <CreateIntegration />
            </Modal>
        </S.Wrapper>
    )
}

export default withTranslation()(Dashboard)

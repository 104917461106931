import { createGlobalStyle } from 'styled-components'

const Styles = createGlobalStyle`

    body,
    html,
    a {
        font-family: 'Ubuntu', sans-serif;
    }
    
    //:root {
    //    font-size: 100%;
    //    
    //    @media screen and (max-width: 479px) {
    //        font-size: 65%;
    //    }
    //}


    body {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        background: #fff;
        overflow-x: hidden;
        direction: ${(props) => props.direction};
    }

    a:hover {
        color: #000;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Ubuntu', sans-serif;
        color: #333;
        line-height: 3.0625rem;
        padding: 0;
        margin: 0;
        
        @media screen and (max-width: 479px) {
            font-size: 1.625rem;
        }
    }

    p {
        color: #343D48;
        font-size: 1.125rem;
    }

    h1 {
        font-size: 3.5rem;
    }
    
    h2 {
        font-size: 2.5rem;
    }
    
    h3 {
        font-size: 2rem;
    }
    
    h4 {
        font-size: 1.5rem;
    }
    
    h5 {
        font-size: 1.25rem;
    }
    
    h6 {
        font-size: 1rem;
    }
    
    @media only screen and (max-width: 400px) {
        h1 {
            font-size: 2rem;
        }
        
        h2 {
            font-size: 1.5rem;
        }
        
        h3 {
            font-size: 1.25rem;
        }
        
        h4 {
            font-size: 1rem;
        }
        
        h5 {
            font-size: 0.875rem;
        }
        
        h6 {
            font-size: 0.6rem;
        }
    }
    

    a {
        text-decoration: none;
        outline: none;
        color: #112d4f;

        :hover {
            color: #112d4f;
        }
    }

    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        padding: 1.25rem;
        text-align: left;
        padding-top: 2.5rem;
        padding-right: 2rem;
    }

    .anticon,
    .ant-notification-notice-icon-success {
        color: rgb(58, 20, 197);
    }
`

export default Styles

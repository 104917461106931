import React, { useState, Fragment, useEffect } from 'react'
import Loadable from '@loadable/component'
import { Row, Col, Drawer } from 'antd'
import { CSSTransition } from 'react-transition-group'
import { useTranslation, withTranslation } from 'react-i18next'

import Head from './Head'
import * as S from './styles'
import { useAuth } from '../../hooks/AuthProvider'
import { redirect, useLocation, useNavigate } from 'react-router-dom'

const SvgIcon = Loadable(() => import('../../common/SvgIcon'))
const Button = Loadable(() => import('../../common/Button'))

const Header = () => {
    const { t } = useTranslation()
    const user = useAuth()
    const location = useLocation()
    const [isNavVisible] = useState(false)
    const [isSmallScreen] = useState(false)
    const [visible, setVisibility] = useState(false)
    const [show, setShow] = useState(true)

    useEffect(() => {
        // runs on location, i.e. route, change
        if (
            user &&
            window.location.pathname !== '/' &&
            window.location.pathname !== '/privacy-policy' &&
            window.location.pathname !== '/terms-of-use'
        ) {
            setShow(false)
        } else {
            setShow(true)
        }
    }, [location])

    if (!show) {
        return <Head />
    }

    const showDrawer = () => {
        setVisibility(!visible)
    }

    const onClose = () => {
        setVisibility(!visible)
    }

    const MenuItem = () => {
        const navigate = useNavigate()

        const scrollTo = (id) => {
            const element = document.getElementById(id)
            element?.scrollIntoView({
                behavior: 'smooth',
            })
            setVisibility(false)
        }
        return (
            <Fragment>
                <Head />
                {/*<S.CustomNavLinkSmall onClick={() => scrollTo("about")}>*/}
                {/*  <S.Span>{t("About")}</S.Span>*/}
                {/*</S.CustomNavLinkSmall>*/}
                {/*<S.CustomNavLinkSmall onClick={() => scrollTo("mission")}>*/}
                {/*  <S.Span>{t("Mission")}</S.Span>*/}
                {/*</S.CustomNavLinkSmall>*/}
                {/*<S.CustomNavLinkSmall onClick={() => scrollTo("product")}>*/}
                {/*  <S.Span>{t("Product")}</S.Span>*/}
                {/*</S.CustomNavLinkSmall>*/}
                <S.CustomNavLinkSmallBorder onClick={() => navigate('/login')}>
                    <S.Span>{t('Log In')}</S.Span>
                </S.CustomNavLinkSmallBorder>
                {/*<S.CustomNavLinkSmall*/}
                {/*  style={{ width: "180px" }}*/}
                {/*  onClick={() => scrollTo("contact")}*/}
                {/*>*/}
                {/*  <S.Span>*/}
                {/*    <Button>{t("Contact")}</Button>*/}
                {/*  </S.Span>*/}
                {/*</S.CustomNavLinkSmall>*/}
            </Fragment>
        )
    }

    return (
        <S.Header>
            <S.Container>
                <Row
                    type="flex"
                    justify="space-between"
                    align="center"
                    gutter={20}
                >
                    <S.LogoContainer to="/" aria-label="homepage">
                        {/*<SvgIcon src="logo.svg" />*/}
                        Kliper
                    </S.LogoContainer>
                    <S.NotHidden>
                        <MenuItem />
                    </S.NotHidden>
                    <S.Burger onClick={showDrawer}>
                        <S.Outline />
                    </S.Burger>
                </Row>
                <CSSTransition
                    in={!isSmallScreen || isNavVisible}
                    timeout={350}
                    classNames="NavAnimation"
                    unmountOnExit
                >
                    <Drawer
                        closable={false}
                        visible={visible}
                        onClose={onClose}
                    >
                        <Col style={{ marginBottom: '2.5rem' }}>
                            <S.Label onClick={onClose}>
                                <Col span={12}>
                                    <S.Menu>Menu</S.Menu>
                                </Col>
                                <Col span={12}>
                                    <S.Outline padding="true" />
                                </Col>
                            </S.Label>
                        </Col>
                        <MenuItem />
                    </Drawer>
                </CSSTransition>
            </S.Container>
        </S.Header>
    )
}

export default Header

import styled from 'styled-components'

export const PostWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',
    gap: '4px',

    'div:nth-child(2)': {
        flex: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    '.approved': {
        color: 'green',
        padding: '8px',
        margin: '3px',
        fontSize: '0.6rem',
    },
}))

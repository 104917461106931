import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    gap: 10px;
    font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
`

export const ContainerLeft = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const ContainerRight = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #e0e0e0;
    font-family: 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    justify-content: center;
    align-items: center;
    padding: 20px;
    align-content: center;
`

export const SmallLetters = styled.div`
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`

export const AdobeButton = styled.button`
    border: none;
    background: none;
    padding: 8px 0;
    cursor: pointer;
`

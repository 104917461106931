import React, { Fragment, useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import i18n from 'i18next'
import { withTranslation } from 'react-i18next'
import Loadable from '@loadable/component'
import Fade from 'react-reveal/Fade'

import * as S from './styles'
import { useAuth } from '../../hooks/AuthProvider'
import { useLocation } from 'react-router-dom'

const SvgIcon = Loadable(() => import('../../common/SvgIcon'))
const Container = Loadable(() => import('../../common/Container'))

const Footer = ({ t }) => {
    const user = useAuth()
    const location = useLocation()
    const [show, setShow] = useState(true)

    useEffect(() => {
        // runs on location, i.e. route, change
        if (
            user &&
            window.location.pathname !== '/' &&
            window.location.pathname !== '/privacy-policy' &&
            window.location.pathname !== '/terms-of-use'
        ) {
            setShow(false)
        } else {
            setShow(true)
        }
    }, [location])

    if (!show) {
        return null
    }

    const SocialLink = ({ href, src }) => {
        return (
            <a
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                key={src}
                aria-label={src}
            >
                <SvgIcon src={src} width="25px" height="25px" />
            </a>
        )
    }

    return (
        <>
            <S.Footer>
                <Row
                    type="flex"
                    justify="space-between"
                    align="center"
                    gutter={20}
                >
                    <Col span={8}>
                        <S.Language>{t('Contact')}</S.Language>
                        <S.Large to="/">{t('Tell us everything')}</S.Large>
                        <S.Para>
                            {t(
                                `Do you have any question regarding the project? Feel free to reach out.`
                            )}
                        </S.Para>
                        <a href="mailto:admin@kliper.app">
                            <S.Chat>{t(`Let's Chat`)}</S.Chat>
                        </a>
                    </Col>
                    <Col span={8}>
                        <S.Title>{t('Policy')}</S.Title>
                        <S.Large to="/privacy-policy">
                            {t('Privacy Policy')}
                        </S.Large>
                        {/*<S.Large left="true" to="/">*/}
                        {/*  {t("Software Principles")}*/}
                        {/*</S.Large>*/}

                        <S.Large to="/terms-of-use">
                            {t('Terms of Use')}
                        </S.Large>
                        {/*<S.Large left="true" to="/">*/}
                        {/*  {t("Software Principles")}*/}
                        {/*</S.Large>*/}
                    </Col>
                    <Col span={8}>
                        <S.Title>{t('Social')}</S.Title>
                        <S.Large to="/">{t('Coming soon')}</S.Large>
                    </Col>
                </Row>
                {/*<Row type="flex" justify="space-between">*/}
                {/*  <Col lg={10} md={10} sm={12} xs={24}>*/}
                {/*    <S.Empty />*/}
                {/*    /!*<S.Language>{t("ADDRESS")}</S.Language>*!/*/}
                {/*    /!*<S.Para>Rancho Santa Margarita</S.Para>*!/*/}
                {/*    /!*<S.Para>2131 Elk Street</S.Para>*!/*/}
                {/*    /!*<S.Para>California</S.Para>*!/*/}
                {/*  </Col>*/}
                {/*  <Col lg={8} md={8} sm={12} xs={24}>*/}
                {/*    <S.Title>{t("Company")}</S.Title>*/}
                {/*    <S.Large left="true" to="/">*/}
                {/*      {t("About")}*/}
                {/*    </S.Large>*/}
                {/*    <S.Large left="true" to="/">*/}
                {/*      {t("Blog")}*/}
                {/*    </S.Large>*/}
                {/*    <S.Large left="true" to="/">*/}
                {/*      {t("Press")}*/}
                {/*    </S.Large>*/}
                {/*    <S.Large left="true" to="/">*/}
                {/*      {t("Careers & Culture")}*/}
                {/*    </S.Large>*/}
                {/*  </Col>*/}
                {/*  <Col lg={6} md={6} sm={12} xs={24}>*/}
                {/*    <S.Select>*/}
                {/*      <S.Label htmlFor="select-lang">{t("Language")}</S.Label>*/}
                {/*      <S.LangSelect*/}
                {/*        onChange={handleChange}*/}
                {/*        value={i18n.language}*/}
                {/*        id="select-lang"*/}
                {/*      >*/}
                {/*        <option value="en">English</option>*/}
                {/*        <option value="es">Español</option>*/}
                {/*      </S.LangSelect>*/}
                {/*    </S.Select>*/}
                {/*  </Col>*/}
                {/*</Row>*/}
            </S.Footer>
            {/*<S.Extra>*/}
            {/*    <Container border="true">*/}
            {/*        <Row*/}
            {/*            type="flex"*/}
            {/*            justify="space-between"*/}
            {/*            align="middle"*/}
            {/*            style={ { paddingTop: '3rem' } }*/}
            {/*        >*/}
            {/*            <S.NavLink to="/">*/}
            {/*                <S.LogoContainer>*/}
            {/*<SvgIcon*/}
            {/*  src="logo.svg"*/}
            {/*  aria-label="homepage"*/}
            {/*  width="101px"*/}
            {/*  height="64px"*/}
            {/*/>*/}
            {/*    </S.LogoContainer>*/}
            {/*</S.NavLink>*/}
            {/*<S.FooterContainer>*/}
            {/*  <SocialLink*/}
            {/*    href="https://github.com/username"*/}
            {/*    src="github.svg"*/}
            {/*  />*/}
            {/*  <SocialLink*/}
            {/*    href="https://twitter.com/username"*/}
            {/*    src="twitter.svg"*/}
            {/*  />*/}
            {/*  <SocialLink*/}
            {/*    href="https://www.linkedin.com/in/username/"*/}
            {/*    src="linkedin.svg"*/}
            {/*  />*/}
            {/*  <SocialLink*/}
            {/*    href="https://github.com/username/"*/}
            {/*    src="instagram.svg"*/}
            {/*  />*/}
            {/*  <SocialLink*/}
            {/*    href="https://medium.com/username/"*/}
            {/*    src="medium.svg"*/}
            {/*  />*/}
            {/*</S.FooterContainer>*/}
            {/*        </Row>*/}
            {/*    </Container>*/}
            {/*</S.Extra>*/}
        </>
    )
}

export default withTranslation()(Footer)

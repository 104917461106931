import styled from 'styled-components'

export const PostImg = styled.div`
    margin-top: 4px;
    background-image: url('${(props) => props.url}');
    background-color: rgb(253, 253, 253);
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 200px;
`

import { styled } from '@mui/material/styles'
import { Layout } from 'antd'

export const DLayout = styled(Layout)(({ theme }) => ({
    height: '100vh',
    minHeight: '100vh',
    backgroundColor: '#f5f4f1',
    fontFamily: 'inherit !important',

    '.ant-layout': {
        fontFamily: 'inherit !important',
        backgroundColor: '#f5f4f1 !important',
    },

    '.ant-menu': {
        padding: '8px',
        fontFamily: 'inherit !important',
    },

    '.sidebar, .ant-menu-light, .ant-layout-header': {
        backgroundColor: '#f5f4f1 !important',
        borderInline: 'none !important',
    },

    '.ant-layout-sider-children': {
        borderRight: '1px solid rgba(0, 0, 0, 0.05)',
    },

    '.ant-menu-item': {
        borderRadius: '20px',
        fontFamily: 'inherit !important',
    },

    '.ant-menu-item-selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        color: 'black',
    },

    '.ant-layout-content': {
        backgroundColor: 'initial !important',
        padding: 'initial !important',
        margin: '0 2rem !important',
    },
}))

export const Logo = styled('div')(({ theme }) => ({
    color: '#333',
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: '1rem 0',
    textAlign: 'center',
    letterSpacing: '0.1em',
}))

import React from 'react'
import { FacebookPostContainer } from './styles'
import SvgIcon from '../../../SvgIcon'
import { useTranslation } from 'react-i18next'
import PostAttachment from '../../../PostAttachment'

const FacebookPost = ({ text, author, date, attachment }) => {
    const { t } = useTranslation()

    return (
        <FacebookPostContainer>
            <div className="post-header">
                <img
                    className="profile-picture"
                    src="https://via.placeholder.com/50"
                    alt="Profile"
                />
                <div className="author-info">
                    <p className="author-name">{author}</p>
                    <p className="post-date">{date || t('Just now')}</p>
                </div>
            </div>
            <div className="post-content">
                <p>{text}</p>
            </div>
            {attachment && (
                <div className="post-attachment">
                    <PostAttachment url={attachment} alt="Post attachment" />
                </div>
            )}
            <div className="post-actions">
                <div>
                    <SvgIcon
                        width={18}
                        height={18}
                        src="social/fb-like.svg"
                        alt="like"
                    />
                    <span>Like</span>
                </div>
                <div>
                    <SvgIcon
                        width={18}
                        height={18}
                        src="social/fb-comment.svg"
                        alt="comment"
                    />
                    <span>Comment</span>
                </div>
                <div>
                    <SvgIcon
                        width={18}
                        height={18}
                        src="social/fb-share.svg"
                        alt="share"
                    />
                    <span>Share</span>
                </div>
            </div>
        </FacebookPostContainer>
    )
}

export default FacebookPost

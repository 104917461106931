import { styled as astyles, useTheme } from '@mui/material/styles'
import styled from 'styled-components';

export const drawerWidth = 240

export const Main = astyles( 'main' )( ( { theme, open } ) => ( {
    fontFamily: 'inherit !important',
    flexGrow: 1,
    //padding: theme.spacing( 3 ),
    transition: theme.transitions.create( 'margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    } ),
    marginRight: 0,
    ...( open && {
        transition: theme.transitions.create( 'margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        } ),
        marginRight: drawerWidth,
    } ),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: 'relative',
    height: '100%',

    '.Kalend__main *': {
        fontFamily: 'inherit !important',
    },

    '.Kalend__CalendarHeader-tall, .Kalend__CalendarBody': {
        backgroundColor: 'white',
        boxShadow: 'none',
        border: '1px solid #e0e0e0'
    },

    '.Kalend__CalendarHeader-tall': {
        borderBottomRightRadius: '0',
        borderBottomLeftRadius: '0',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
    },

    '.Kalend__CalendarBody': {
        borderBottomRightRadius: '20px',
        borderBottomLeftRadius: '20px',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        borderTop: '1px solid #e0e0e0',
    },

    '.Kalend__CalendarBodyHours__wrapper': {
        borderRight: '1px solid #e0e0e0',
    },

    '.Kalend__CalendarHeaderCol': {
        paddingTop: '8px',
    },

    '.Kalend__CalendarHeaderDates__circle': {
        fontSize: '1.3em'
    },
    '.Kalend__text.Kalend__CalendarBodyHours__line': {
        background: 'none !important',
    },
    '.Kalend__button.Kalend__Event-normal.Kalend__ButtonBase': {
        width: '96% !important',
        position: 'initial !important',
        height: 'auto !important',
    }
} ) )

export const DrawerContent = astyles( 'div' )( ( { theme } ) => ( {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing( 2 ),
    width: drawerWidth,
} ) )

export const EventWrapper = astyles( 'div' )( ( { theme } ) => ( {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '270px',
    padding: '8px',
    width: '100%',

    img : {

    },

    span: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100%',
        fontSize: '12px',
    }
} ) )


export const PostImg = styled.div`
    margin-top: 4px;
    background-image: url(${props => props.url || "https://www.facebook.com/keren"});
    background-color: rgb(253, 253, 253);
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 200px;
`;
export const PostHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;

    div {
        font-size: 12px;
        min-width: 0;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:first-child {
            flex: 1;
        }
    }
`;

export const Account = styled.div`
    margin: 8px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 4px;
    border: 1px solid #e0e0e0;
    border-radius: 20px;
`;
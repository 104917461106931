import React, { useState } from 'react'
import 'kalend/dist/styles/index.css'
import { Select } from 'antd'
import * as S from './styles'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import moment from 'moment'

const { Option } = Select
const Settings = () => {
    const { t, i18n } = useTranslation()
    const [cookies, setCookie] = useCookies(['language'])

    const onChange = (language) => {
        i18n.changeLanguage(language)
        setCookie('language', language, { path: '/' })
        moment.locale(language)
    }

    const languageOptions = [
        { label: t('English'), value: 'en' },
        { label: t('Hebrew'), value: 'he' },
        { label: t('Spanish'), value: 'es' },
        { label: t('French'), value: 'fr' },
    ]

    const defaultValue =
        languageOptions.find((x) => x.value === i18n.language) ||
        languageOptions[0]

    const themeOptions = [
        { label: t('Light'), value: 'light' },
        { label: t('Dark'), value: 'dark' },
    ]

    return (
        <S.Wrapper>
            <S.Top>
                <S.Title>{t('Settings')}</S.Title>
            </S.Top>
            <S.Content>
                <S.Box>
                    <S.Content>
                        <div>{t('Language Preference')}</div>
                        <Select
                            defaultValue={defaultValue}
                            style={{ width: 120 }}
                            onChange={onChange}
                        >
                            {languageOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </S.Content>
                    <br />
                    <S.Content>
                        <div>{t('Theme')}</div>
                        <Select
                            defaultValue={themeOptions[0]}
                            style={{ width: 120 }}
                        >
                            {themeOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </S.Content>
                </S.Box>
            </S.Content>
        </S.Wrapper>
    )
}

export default Settings

import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    gap: 10px;
    justify-items: center;
    justify-content: space-around;
    align-items: center;
`

export const Integration = styled.div`
    border-radius: 20px;
    width: 100px;
    height: 100px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 6px;
    justify-content: center;
    align-items: center;
    font-size: 16px;

    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        transition: all 0.1s ease-in;
    }
`

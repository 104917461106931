import React from 'react'

const SvgIcon = ({ src, width, height, style }) => (
    <img
        src={`/img/svg/${src}`}
        alt={src}
        width={width}
        height={height}
        fetchPriority={'high'}
        style={style}
    />
)

export const SocialIcon = ({ type, width }) => {
    const srcPath =
        `social/${type === 'bussiness manager' ? 'facebook' : type.indexOf(' ') >= 0 ? type.split(' ')[0] : type.split('_')[0]}.svg`.toLowerCase()
    return (
        <img
            src={`/img/svg/${srcPath}`}
            alt={srcPath}
            width={width}
            height={width}
        />
    )
}

export default SvgIcon

// /* eslint-disable camelcase */
// /**
//  *
//  * LoginSocialTiktok
//  *
//  */
// // import { PASS_CORS_KEY } from 'helper/constants';
// import React, { memo, useCallback, useEffect } from 'react';
// // import { IResolveParams, objectType } from '..';
// //
// // interface Props {
// //   state?: string;
// //   scope?: string;
// //   client_key: string;
// //   // client_secret: string;
// //   className?: string;
// //   redirect_uri: string;
// //   // isOnlyGetToken?: boolean;
// //   // isOnlyGetCode?: boolean;
// //   children?: React.ReactNode;
// //   onLoginStart?: () => void;
// //   // onLogoutSuccess?: () => void;
// //   onReject: (reject: string | objectType) => void;
// //   onResolve: ({ provider, data }: IResolveParams) => void;
// // }
//
// const TIKTOK_URL = 'https://www.tiktok.com';
// // const TIKTOK_API_URL: string = 'https://open-api.tiktok.com';
// // const PREVENT_CORS_URL: string = 'https://cors.bridged.cc';
//
// export const LoginTiktok = ({
//   state = '',
//   scope = 'user.info.basic',
//   client_key,
//   // client_secret,
//   className = '',
//   redirect_uri,
//   // isOnlyGetToken = false,
//   // isOnlyGetCode = false,
//   children,
//   // onReject,
//   onResolve,
//   onLoginStart,
// }) => {
//   useEffect(() => {
//     const popupWindowURL = new URL(window.location.href);
//     const code = popupWindowURL.searchParams.get('code');
//     const state = popupWindowURL.searchParams.get('state');
//     if (state?.includes('_tiktok') && code) {
//       localStorage.setItem('tiktok', code);
//       window.close();
//     }
//   }, []);
//
//   // const getProfile = useCallback(
//   //   (data: objectType) => {
//   //     fetch(`${PREVENT_CORS_URL}/${TIKTOK_API_URL}/user`, {
//   //       method: 'GET',
//   //       headers: {
//   //         Authorization: `token ${data.access_token}`,
//   //         'x-cors-grida-api-key': PASS_CORS_KEY,
//   //       },
//   //     })
//   //       .then(res => res.json())
//   //       .then((response: any) => {
//   //         onResolve({ provider: 'tiktok', data: { ...response, ...data } });
//   //       })
//   //       .catch(err => {
//   //         onReject(err);
//   //       });
//   //   },
//   //   [onReject, onResolve],
//   // );
//
//   // const getAccessToken = useCallback(
//   //   (code: string) => {
//   //     if (isOnlyGetCode) onResolve({ provider: 'tiktok', data: { code } });
//   //     else {
//   //       const params = {
//   //         code,
//   //         client_key,
//   //         client_secret,
//   //         grant_type: 'authorization_code',
//   //       };
//   //       const headers = new Headers({
//   //         'Content-Type': 'application/x-www-form-urlencoded',
//   //         'x-cors-grida-api-key': PASS_CORS_KEY,
//   //       });
//
//   //       fetch(`${PREVENT_CORS_URL}/${TIKTOK_API_URL}/oauth/access_token/`, {
//   //         method: 'POST',
//   //         headers,
//   //         body: new URLSearchParams(params),
//   //       })
//   //         .then(response => response.text())
//   //         .then(response => {
//   //           console.log(response);
//   //           // const data: objectType = {};
//   //           // const searchParams: any = new URLSearchParams(response);
//   //           // for (const p of searchParams) {
//   //           //   data[p[0]] = p[1];
//   //           // }
//   //           // if (data.access_token) {
//   //           //   if (isOnlyGetToken) onResolve({ provider: 'tiktok', data });
//   //           //   else getProfile(data);
//   //           // } else onReject('no data');
//   //         })
//   //         .catch(err => {
//   //           onReject(err);
//   //         });
//   //     }
//   //   },
//   //   [onReject, onResolve, client_key, client_secret, isOnlyGetCode],
//   // );
//
//   const handlePostMessage = useCallback(
//     async ({ type, code, provider }) =>
//       type === 'code' &&
//       provider === 'tiktok' &&
//       code &&
//       onResolve({ provider: 'tiktok', data: { code } }),
//     // getAccessToken(code),
//     [onResolve],
//   );
//
//   const onChangeLocalStorage = useCallback(() => {
//     window.removeEventListener('storage', onChangeLocalStorage, false);
//     const code = localStorage.getItem('tiktok');
//     if (code) {
//       handlePostMessage({ provider: 'tiktok', type: 'code', code });
//       localStorage.removeItem('instagram');
//     }
//   }, [handlePostMessage]);
//
//   const onLogin = useCallback(() => {
//     onLoginStart && onLoginStart();
//
//     window.addEventListener('storage', onChangeLocalStorage, false);
//     const oauthUrl = `${TIKTOK_URL}/v2/auth/authorize?client_key=${client_key}&scope=${scope}&state=${
//       state + '_tiktok'
//     }&redirect_uri=${redirect_uri}&response_type=code`;
//     const width = 450;
//     const height = 730;
//     const left = window.screen.width / 2 - width / 2;
//     const top = window.screen.height / 2 - height / 2;
//     window.open(
//       oauthUrl,
//       'Github',
//       'menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=' +
//         width +
//         ', height=' +
//         height +
//         ', top=' +
//         top +
//         ', left=' +
//         left,
//     );
//   }, [
//     scope,
//     state,
//     client_key,
//     redirect_uri,
//     onLoginStart,
//     onChangeLocalStorage,
//   ]);
//
//   return (
//     <div className={className} onClick={onLogin}>
//       {children}
//     </div>
//   );
// };
//
// export default memo(LoginTiktok);

import React, { useState } from 'react'
import { useAuth } from '../../hooks/AuthProvider'
import { openPopup } from '../../utils/popup'

const TikTokLogin = ({ children }) => {
    const { user } = useAuth()
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [userInfo, setUserInfo] = useState(null)

    // Define your TikTok app credentials
    const clientKey = 'awmkqq0883sl44g1'
    const redirectUri = window.location.href

    // TikTok authorization URL
    const authorizationUrl = `https://www.tiktok.com/v2/auth/authorize?client_key=${clientKey}&scope=user.info.basic,video.publish,video.upload&response_type=code&redirect_uri=${redirectUri}`

    // Handle the TikTok login
    const handleLogin = () => {
        window.location.href = authorizationUrl
        // const scope = 'user.info.basic'
        // const responseType = 'code'
        // const url = `https://www.tiktok.com/v2/auth/authorize?client_key=${clientKey}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}`
        //
        // const popup = openPopup(url, 'tiktokLogin')
        //
        // const interval = setInterval(() => {
        //     if (popup.closed) {
        //         clearInterval(interval)
        //         // window.location.reload()
        //     }
        // }, 500)
    }

    // Once the user is redirected back to your app, handle the response
    // const handleCallback = async (code) => {
    //     debugger
    //     // Make an API call to exchange the authorization code for an access token
    //     const response = await fetch(
    //         `${process.env.REACT_APP_BACKEND_PREFIX_URL}/oauth/tiktok`,
    //         {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             method: 'POST',
    //             body: JSON.stringify({
    //                 account_id: user.accountId,
    //                 code: code,
    //             }),
    //         }
    //     )
    // const data = await response.json()
    //
    // // Once you have the access token, you can use it to make authenticated requests
    // const accessToken = data.access_token
    // console.log('Access Token:', accessToken)
    //
    // // Make an API call to get the user's information
    // const userResponse = await fetch('oauth/tiktok', {
    //     method: 'POST',
    //     headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //     },
    //     body: JSON.stringify({ code: code }),
    // })
    // const userInfo = await userResponse.json()
    // setUserInfo(userInfo)
    // setIsLoggedIn(true)
    //}

    // Use the URL to determine if the user has been redirected back to the app
    // React.useEffect(() => {
    //     const urlParams = new URLSearchParams(window.location.search)
    //     const code = urlParams.get('code')
    //
    //     if (code) {
    //         handleCallback(code)
    //     }
    // }, [])

    return <div onClick={handleLogin}>{children}</div>
}

export default TikTokLogin

// src/components/Callback.js

import React, { useEffect } from 'react'
import axios from 'axios'
import { useLocation } from 'react-router-dom'

const Callback = () => {
    const location = useLocation()
    const clientId = process.env.REACT_APP_TIKTOK_CLIENT_ID
    const redirectUri = process.env.REACT_APP_TIKTOK_REDIRECT_URI

    useEffect(() => {
        const fetchToken = async () => {
            const code = new URLSearchParams(location.search).get('code')
            if (code) {
                try {
                    const response = await axios.post(
                        'https://open-api.tiktok.com/oauth/access_token/',
                        {
                            client_key: clientId,
                            code,
                            grant_type: 'authorization_code',
                            redirect_uri: redirectUri,
                        }
                    )
                    const { access_token } = response.data
                    console.log('Access Token:', access_token)
                } catch (error) {
                    console.error('Error fetching access token:', error)
                }
            }
        }

        fetchToken()
    }, [location.search, clientId, redirectUri])

    return <div>Loading...</div>
}

export default Callback

import { styled } from '@mui/material/styles'

export const Wrapper = styled('div')(({ theme }) => ({
    backgroundColor: '#f5f4f1',
    fontFamily: 'Ubuntu, sans-serif',
}))
export const Content = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
}))

export const Title = styled('div')(({ theme }) => ({
    fontSize: '1.5rem',
}))

export const Button = styled('button')(({ theme }) => ({
    backgroundColor: '#745ec2',
    color: 'white',
    borderRadius: '20px',
    fontFamily: 'Ubuntu, sans-serif',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    border: 'none',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: '#6241de',
    },
}))

export const Top = styled('div')(({ theme }) => ({
    fontSize: '1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '1rem',
}))

export const Box = styled('div')(({ theme }) => ({
    backgroundColor: '#fff',
    borderRadius: '1.5rem',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
}))

export const Header = styled('div')(({ theme }) => ({
    fontSize: '1.2rem',
    fontWeight: 'bold',
    paddingBottom: '10px',
}))

export const Asset = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: '6px 0',
    gap: '4px',
}))

export const Post = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',
    gap: '4px',

    div: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: 0,
    },

    'div:nth-child(2)': {
        flex: 1,
    },

    '.approved': {
        color: 'green',
        padding: '8px',
        margin: '3px',
        fontSize: '0.6rem',
    },
}))
export const PostsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    margin: '2px 0',
    gap: '4px',
}))

import React, { useState } from 'react'

import * as S from './styles'
import { useAuth } from '../../hooks/AuthProvider'
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google'
import { navigate } from '@reach/router'
import { jwtDecode } from 'jwt-decode'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

const Login = () => {
    const { login } = useAuth()
    const { t } = useTranslation()
    const [error, serError] = useState('')
    const [input, setInput] = useState({
        username: '',
        password: '',
    })

    const googleLogin = useGoogleLogin({
        flow: 'implicit',
        onSuccess: (codeResponse) => handleSuccess(codeResponse),
    })

    const handleSubmitEvent = async (e) => {
        serError('')
        e.preventDefault()
        if (input.username !== '' && input.password !== '') {
            const response = await login(input)
            if (response) {
                serError('Invalid credentials')
            }
        }
    }

    // This function will be called when the login is successful
    const handleSuccess = async (response) => {
        serError('')

        const userData = await axios.get(
            'https://www.googleapis.com/oauth2/v1/userinfo?alt=json',
            {
                headers: {
                    Authorization: `Bearer ${response.access_token}`,
                },
            }
        )

        await login({ ...userData.data, google: true })
    }

    // This function will be called when the login fails
    const handleFailure = (response) => {
        console.log('Login failed.')
        console.log('Error:', response)
    }

    const handleInput = (e) => {
        const { name, value } = e.target
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }))
    }

    const responseFacebook = (e) => {
        console.log(e)
    }

    return (
        <S.LoginPageContainer>
            <S.LoginFormContainer>
                <S.LoginForm onSubmit={handleSubmitEvent}>
                    <S.FormTitle>{t('Login')}</S.FormTitle>
                    <div style={{ fontSize: '12px', padding: '20px' }}>
                        For a demo, insert 'test@kliper.app' and '1234'
                    </div>
                    <S.FormGroup>
                        <S.Label htmlFor="email">{t('Email')}</S.Label>
                        <S.Input
                            type="email"
                            id="email"
                            name={'username'}
                            value={input.username}
                            onChange={handleInput}
                            required
                            pattern="test@kliper\.app"
                            title="The email must be test@kliper.app"
                        />
                    </S.FormGroup>
                    <S.FormGroup>
                        <S.Label htmlFor="password">{t('Password')}</S.Label>
                        <S.Input
                            type="password"
                            id="password"
                            name={'password'}
                            value={input.password}
                            onChange={handleInput}
                            required
                        />
                    </S.FormGroup>
                    <S.SubmitButton type="submit">{t('Login')}</S.SubmitButton>
                </S.LoginForm>
            </S.LoginFormContainer>
            <div style={{ color: 'red', padding: '8px' }}>{error}</div>
            <div style={{ marginTop: '4rem' }}>{t('Login with:')}</div>
            <S.SocialLoginContainer>
                <div>
                    <img
                        onClick={googleLogin}
                        width="50px"
                        src={'img/svg/social/google.png'}
                        alt={'google'}
                    />
                </div>
                <div>
                    <FacebookLogin
                        appId="722597069856816"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={responseFacebook}
                        cssClass="facebook-login-btn"
                        render={(renderProps) => (
                            <img
                                onClick={renderProps.onClick}
                                width="50px"
                                src={'img/svg/social/facebook.png'}
                                alt={'facebook'}
                            />
                        )}
                        scope="pages_manage_engagement,pages_manage_metadata,pages_manage_posts,pages_read_engagement,pages_read_user_engagement,pages_show_list,publish_video"
                    />
                </div>
            </S.SocialLoginContainer>
        </S.LoginPageContainer>
    )
}

export default Login

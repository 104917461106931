import React from 'react'
import { PostImg } from './styles'

const PostAttachment = ({ url }) => {
    const splitByDot = url.split('.')
    const type = splitByDot[splitByDot.length - 1]

    if (type === 'mp4') {
        return (
            <video
                onMouseOver={(event) => {
                    try {
                        event.target.play()
                    } catch (e) {
                        console.error(e)
                    }
                }}
                onMouseOut={(event) => event.target.pause()}
                src={url}
                muted="muted"
                style={{ maxHeight: '370px', width: '100%' }}
            />
        )
    }
    return <PostImg url={url} />
}

export default PostAttachment

export const EVENT_MIN_HEIGHT = 25
export const EVENT_TABLE_DELIMITER = 1
export const TABLE_COL_SPACE = 5
export const EVENT_TABLE_DELIMITER_SPACE = 8
export const HOUR_COL_HEIGHT = 40
export const CALENDAR_OFFSET_LEFT = 0
export const DEFAULT_HOUR_HEIGHT = 40
export const SHOW_TIME_THRESHOLD = 60
export const MONTH_DAY_HEADER_HEIGHT = 25
export const MONTH_EVENT_HEIGHT = 14
export const SCROLLBAR_WIDTH = 15

export const hoursArrayString = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
]

import React, { useEffect, useRef, useState } from 'react'

const AdobeEditor = (props) => {
    const editorRef = useRef( null )
    const [ editorInstance, setEditorInstance ] = useState( null )

    useEffect( () => {
            const initializeEditor = async () => {
               // console.log(!editorInstance)
              //  if ( !editorInstance ) {
                    // window.CCEverywhere.initialize( {
                    //     clientID: '1434102a17f4443f8f4cbec1a9f971ca',
                    //     appName: 'Kliper',
                    // } )

                    //const editor = window.keren.editor

                    // const { editor } = await window.CCEverywhere.initialize( {
                    //     clientId: '1434102a17f4443f8f4cbec1a9f971ca',
                    //     appName: 'Kliper',
                    // } )

                    // const editor = new window.AdobeCreativeSDK.CCExpress.Editor( {
                    //     container: editorRef.current,
                    //     embedOptions: {
                    //         theme: 'dark',
                    //     },
                    //     onSave: handleSave,
                    // } )
                    //setEditorInstance( editor )


                    const callbacks = {
                        onCancel: () => {
                        },
                        onPublish: ( intent, publishParams ) => {
                            const localData = { project: publishParams.projectId, image: publishParams.asset[ 0 ].data }
                            updateImage( localData )
                        },
                        onError: ( err ) => {
                            console.error( 'Error received is', err.toString() )
                        },
                    }

                    let appConfig = { callbacks: callbacks, modalParams: {parentElementId: "adobe-express-container"} }

                    const updateImage = ( localData ) => {
                        props?.onPublish(localData)
                    }
                     window.ccEverywhere.editor.create( null, appConfig, [], {zIndex: 99999})
            }

            initializeEditor()
        }, [  ],
    )


    const handleSave = ( blob ) => {
        // Handle the design blob (for example, uploading to the backend)
        const formData = new FormData()
        formData.append( 'file', blob, 'design.png' )

        fetch( 'https://your-backend-api/upload', {
            method: 'POST',
            body: formData,
        } )
            .then( response => response.json() )
            .then( data => {
                console.log( 'File uploaded successfully:', data )
            } )
            .catch( error => {
                console.error( 'Error uploading file:', error )
            } )
    }

    return (
        <div ></div>
    )
}

export default AdobeEditor
